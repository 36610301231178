<template>
  <elements-tab-container
    v-slot="props"
    :items="getAllElementsToBeRemoved"
    :selected-world-type="selectedWorldType"
    :update-selected-world-type="updateSelectedWorldType"
  >
    <search-results-table
      v-model="searchElement"
      :items="getAllElementsToBeRemoved[selectedWorldType]"
      :header-color="props.worldType.color"
      :show-no-data-found="!getAllElementsToBeRemoved[selectedWorldType].length"
      :product-model-change="true"
    >
      <template #default="{ item }">
        <mi-expansion-item
          :class="{ [`collection-item__table-${ props.worldType.color }`]: true }"
          header-class="items-center"
          :default-opened="true"
          dense
        >
          <template #header>
            <search-results-item-title :code-id="item.element.id2" :name="item.element.name">
            </search-results-item-title>
          </template>
          <mi-list class="full-width">
            <mi-list-item
              v-for="(subItem, index) in item.element[props.subElementsType]"
              :key="index"
              class="items-center q-pl-xl"
              @click="handleSubItemClick()"
            >
              <search-results-item-title
                :code-id="subItem.id2"
                :name="subItem.name"
              ></search-results-item-title>
            </mi-list-item>
          </mi-list>
        </mi-expansion-item>
      </template>
    </search-results-table>
  </elements-tab-container>
</template>

<script>
  import { ref, computed } from 'vue'
  import SearchResultsTable from '@/components/search/search-results/SearchResultsTable.vue'
  import SearchResultsItemTitle from '@/components/search/search-results/SearchResultsItemTitle.vue'
  import ElementsTabContainer from '@/components/product-model/elements-change/ElementsTabContainer.vue'
  import { SEARCH_RESULT_TYPES } from '@/constants'
  import { filterElements, filterElementByModificationType } from '@/components/product-model/utils'

  export default {
    name: 'RemoveElementsTab',
    components: { SearchResultsTable, SearchResultsItemTitle, ElementsTabContainer },
    props: {
      modifiedElements: {
        type: Object,
        required: false,
        default: () => {}
      },
      selectedWorldType: {
        type: String,
        required: true
      },
      updateSelectedWorldType: {
        type: Function,
        required: true
      }
    },
    setup(props) {
      const searchElement = ref('')

      const getAllElementsToBeRemoved = computed(() => {
        const { options, components } = props.modifiedElements
        const modificationType = 'REMOVED'

        if (props.selectedWorldType === SEARCH_RESULT_TYPES.OPTIONS) {
          return {
            options: filterElements(options, searchElement.value, modificationType),
            components: filterElementByModificationType(components, modificationType)
          }
        }

        return {
          options: filterElementByModificationType(options, modificationType),
          components: filterElements(components, searchElement.value, modificationType)
        }
      })
      return { searchElement, getAllElementsToBeRemoved }
    }
  }
</script>

<style lang="scss" scoped>
  $collection-item-border: 1px solid $mi-anthracite-50;

  .q-item {
    border-top: $collection-item-border;

    .q-item-type:last-child {
      border-bottom: $collection-item-border;
    }
  }

  .q-item__section {
    font-size: 14px;
  }

  .collection-item {
    &__table-yellow ::v-deep(.mi-expansion-item__header) {
      background-color: lighten($mi-yellow-400, 35%);
    }

    &__table-yellow ::v-deep(.mi-list-item) {
      background-color: lighten($mi-yellow-400, 47%);
    }

    &__table-green ::v-deep(.mi-expansion-item__header) {
      background-color: lighten($mi-green-200, 9%);
    }

    &__table-green ::v-deep(.mi-list-item) {
      background-color: lighten($mi-green-500, 59%);
    }
  }
</style>
