<template>
  <elements-tab-container
    v-slot="props"
    :items="getAllAddedElements"
    :selected-world-type="selectedWorldType"
    :update-selected-world-type="updateSelectedWorldType"
  >
    <search-results-table
      v-model="searchElement"
      :items="getAllAddedElements[selectedWorldType]"
      :header-color="props.worldType.color"
      :show-no-data-found="!getAllAddedElements[selectedWorldType]?.length"
      :product-model-change="true"
    >
      <template #default="{ item }">
        <search-results-group
          :key="item.element.id"
          :item="item.element"
          :color="props.worldType.color"
          :selected-elements-ids="selectedAddedElementsIds"
          :handle-item-click="handleItemClick"
          :handle-sub-item-click="handleSubItemClick"
        ></search-results-group>
      </template>
    </search-results-table>
  </elements-tab-container>
</template>

<script>
  import { ref, computed } from 'vue'
  import ElementsTabContainer from '@/components/product-model/elements-change/ElementsTabContainer.vue'
  import SearchResultsGroup from '@/components/search/search-results/SearchResultsGroup.vue'
  import SearchResultsTable from '@/components/search/search-results/SearchResultsTable.vue'
  import { SEARCH_RESULT_TYPES } from '@/constants'
  import { filterElements, filterElementByModificationType } from '@/components/product-model/utils'

  export default {
    name: 'AddElementsTab',
    components: { SearchResultsTable, SearchResultsGroup, ElementsTabContainer },
    props: {
      modifiedElements: {
        type: Object,
        required: false,
        default: () => {}
      },
      selectedAddedElementsIds: {
        type: Object,
        required: true
      },
      selectedWorldType: {
        type: String,
        required: true
      },
      updateSelectedWorldType: {
        type: Function,
        required: true
      },
      handleItemClick: {
        type: Function,
        required: true
      },
      handleSubItemClick: {
        type: Function,
        required: true
      }
    },
    setup(props) {
      const searchElement = ref('')

      const getAllAddedElements = computed(() => {
        const { options, components } = props.modifiedElements
        const modificationType = 'ADDED'

        if (props.selectedWorldType === SEARCH_RESULT_TYPES.OPTIONS) {
          return {
            options: filterElements(options, searchElement.value, modificationType),
            components: filterElementByModificationType(components, modificationType)
          }
        }

        return {
          options: filterElementByModificationType(options, modificationType),
          components: filterElements(components, searchElement.value, modificationType)
        }
      })

      return { searchElement, getAllAddedElements }
    }
  }
</script>
