import { ELEMENT_COLORS, SEARCH_ELEMENT_TYPES, SEARCH_RESULT_TYPES } from '@/constants'

const ELEMENTS = {
  [SEARCH_ELEMENT_TYPES.STRUCTURE]: {
    [SEARCH_RESULT_TYPES.OPTIONS]: {
      items: [],
      totalSize: 0,
      color: ELEMENT_COLORS.YELLOW,
      label: 'Yellow collection',
      totalLabel: 'choice(s)'
    },
    [SEARCH_RESULT_TYPES.COMPONENTS]: {
      items: [],
      totalSize: 0,
      color: ELEMENT_COLORS.GREEN,
      label: 'Green collection',
      totalLabel: 'component variant(s)'
    }
  }
}

export const STRUCTURE_ELEMENTS = ELEMENTS[SEARCH_ELEMENT_TYPES.STRUCTURE]
export const DEFAULT_STRUCTURE_ELEMENT_TYPE = SEARCH_RESULT_TYPES.OPTIONS

export const filterSubElement = (element = {}, searchString = '') => {
  const searchStringLower = searchString.toLowerCase()
  const id2Lower = element.id2?.toLowerCase() || ''
  const nameLower = element.name?.toLowerCase() || ''

  return id2Lower.includes(searchStringLower) || nameLower.includes(searchStringLower)
}

export const filterElementByModificationType = (
  elements, modificationType
) => elements.filter(item => item.modificationType === modificationType)

export const filterElements = (elements, searchElement, modificationType) => elements
  .filter(item => item.modificationType === modificationType)
  .map(item => {
    if (!searchElement) return item
    const { element } = item
    const key = element.choices ? 'choices' : 'componentVariants'
    const newItems = element[key].filter(el => filterSubElement(el, searchElement))
    return { ...item, element: { ...element, [key]: newItems } }
  }).filter(
    item => !!item.element[item.element.choices ? 'choices' : 'componentVariants'].length
  )

export const countTotalSubElements = (items, subElementType) => items.reduce((acc, item) => {
  const subElements = item?.element?.[subElementType] || []
  return acc + subElements.length
}, 0)
