<template>
  <div class="planning-period-range flex">
    <!-- From -->
    <mi-text-field
      :model-value="formattedFrom"
      :bg-color="bgColor"
      :name="fieldName"
      :rules="planningPeriodRules"
      :disable="disable || disableFrom"
      mask="######"
      placeholder="YYYYNN"
      :label="labelFrom"
      prefix="PP"
      data-cy="planning-period-range-from"
      outlined
      @update:model-value="emitFromValue"
    ></mi-text-field>

    <!-- To -->
    <mi-text-field
      :model-value="formattedTo"
      :bg-color="bgColor"
      :name="fieldName"
      :rules="planningPeriodRules"
      :disable="disable || disableTo"
      mask="######"
      placeholder="YYYYNN"
      :label="labelTo"
      data-cy="planning-period-range-to"
      prefix="PP"
      outlined
      @update:model-value="emitToValue"
    ></mi-text-field>
  </div>
</template>

<script>
  import { exactLength, regex, required } from '@/utils/validators'
  import { addPrefixToPlanningPeriod, removePrefixFromPlanningPeriod } from '@/utils/planningPeriod'

  export default {
    name: 'PlanningPeriodRange',
    props: {
      bgColor: {
        type: String,
        required: false,
        default: ''
      },
      fieldName: {
        type: String,
        required: false,
        default: ''
      },
      from: {
        type: String,
        required: false,
        default: ''
      },
      to: {
        type: String,
        required: false,
        default: ''
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      disableFrom: {
        type: Boolean,
        required: false,
        default: false
      },
      disableTo: {
        type: Boolean,
        required: false,
        default: false
      },
      labelFrom: {
        type: String,
        required: false,
        default: 'From'
      },
      labelTo: {
        type: String,
        required: false,
        default: 'To'
      }
    },
    emits: ['update:from', 'update:to'],
    data: () => ({
      planningPeriodRules: [
        required,
        exactLength(6),
        regex(
          /^\d{4}(0[1-9]|[1-2]\d|3[0-6])$/,
          'Field must match the following format YYYYPP (i.e., YYYY=Year, PP=01-36)'
        )
      ]
    }),
    computed: {
      formattedFrom() {
        return removePrefixFromPlanningPeriod(this.from)
      },
      formattedTo() {
        return removePrefixFromPlanningPeriod(this.to)
      }
    },
    methods: {
      emitFromValue(value = '') {
        const formattedValue = addPrefixToPlanningPeriod(value)

        this.$emit('update:from', formattedValue)
      },
      emitToValue(value = '') {
        const formattedValue = addPrefixToPlanningPeriod(value)

        this.$emit('update:to', formattedValue)
      }
    }
  }
</script>

<style lang="scss" scope>
  .planning-period-range {
    gap: 1rem;
    margin-top: .25em;
  }
</style>
