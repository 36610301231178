<template>
  <div class="solution-elements-setup">
    <information-section></information-section>
    <mi-separator></mi-separator>
    <component-list-selection></component-list-selection>
    <div class="solution-elements-setup__actions">
      <mi-btn
        color="secondary"
        @click="handleIgnoreBtnClick"
      >
        Ignore Changes
      </mi-btn>
      <mi-btn
        color="accent"
        @click="handleConfirmBtnClick"
      >
        Confirm selection
      </mi-btn>
    </div>
  </div>
</template>

<script>
  import InformationSection from '@/components/combinations/component-selection/InformationSection.vue'
  import MiSeparator from '@/packages/@mi-library/MiSeparator/MiSeparator.vue'
  import ComponentListSelection from '@/components/combinations/component-selection/ComponentListSelection.vue'
  import { RESET_COMPONENT_FOR_LE, SET_COMPONENTS_FOR_LE } from '@/store/modules/search/mutationTypes'
  import { createNamespacedHelpers } from 'vuex'

  const { mapMutations } = createNamespacedHelpers('search')
  export default {
    name: 'ComponentSelection',
    components: {
      MiSeparator,
      ComponentListSelection,
      InformationSection
    },
    emits: ['close:edit-drawer'],
    data: () => ({
      eventContent: { confirmed: false }
    }),
    methods: {
      ...mapMutations({ SET_COMPONENTS_FOR_LE, RESET_COMPONENT_FOR_LE }),

      handleConfirmBtnClick() {
        this.eventContent.confirmed = true
        this.emitCloseDrawer()
      },
      handleIgnoreBtnClick() {
        this.RESET_COMPONENT_FOR_LE()
        this.emitCloseDrawer()
      },
      emitCloseDrawer() {
        this.$emit('close:edit-drawer', this.eventContent)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .solution-elements-setup {
    width: 474px;
    padding: 16px 24px;

    &__actions {
      margin-top: 12px;
      display: flex;
      gap: 8px;
      justify-content: flex-end;
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .solution-elements-setup {
      width: 100vw;
    }
  }
</style>
