<template>
  <div class="elements-tab">
    <mi-list separator>
      <mi-list-item
        v-for="(worldTypeValue, worldTypeKey) in worldTypes"
        :key="worldTypeKey"
        :to="{ query: { ...$route.query } }"
        :class="{
          'collection-item--active': selectedWorldType === worldTypeKey,
          [`collection-item--${ worldTypeValue.color }`]: true
        }"
        class="collection-item text-weight-bold q-pl-lg"
        @click="updateSelectedWorldType(worldTypeKey)"
      >
        <span class="collection-item__left-border absolute-bottom-left"></span>

        <mi-list-item-section>
          {{ worldTypeValue.label }}
        </mi-list-item-section>

        <mi-list-item-section class="collection-item__items-amount" side>
          {{ totalLabel({ ...worldTypeValue, key: worldTypeKey }) }}
        </mi-list-item-section>
      </mi-list-item>
    </mi-list>
    <div class="col full-height">
      <slot :worldType="currentWorldType" :subElementsType="subElementsType()"></slot>
    </div>
  </div>
</template>

<script>
  import { ref, computed } from 'vue'
  import { countTotalSubElements, STRUCTURE_ELEMENTS } from '@/components/product-model/utils'
  import { SEARCH_RESULT_TYPES } from '@/constants'

  export default {
    name: 'ElementsTabContainer',
    props: {
      items: {
        type: Object,
        required: false,
        default: () => {}
      },
      selectedWorldType: {
        type: String,
        required: true
      },
      updateSelectedWorldType: {
        type: Function,
        required: true
      }
    },
    setup(props) {
      const worldTypes = ref(STRUCTURE_ELEMENTS)
      const currentWorldType = computed(() => (worldTypes.value[props.selectedWorldType]))

      const subElementsType = worldTypeValue => {
        const { OPTIONS, CHOICES, COMPONENT_VARIANTS } = SEARCH_RESULT_TYPES
        const value = worldTypeValue || props.selectedWorldType

        return value === OPTIONS ? CHOICES : COMPONENT_VARIANTS
      }

      const totalLabel = worldType => {
        const subElementType = subElementsType(worldType.key)
        const items = props.items[worldType.key]

        const total = countTotalSubElements(items, subElementType)

        return `${ total } ${ worldType.totalLabel }`
      }

      return {
        worldTypes,
        totalLabel,
        currentWorldType,
        subElementsType
      }
    }
  }
</script>

<style lang="scss" scoped>
  $collection-item-colors: ("yellow": $mi-yellow-400, "green": $mi-green-500);

  .elements-tab {
    display: flex;
    height: 100%;
  }

  .collection-item {
    min-height: 60px;
    color: $mi-anthracite-800;
    transition: none;

    &__left-border {
      width: 8px;
      top: 0;
      z-index: 1;
    }

    &__items-amount {
      color: $mi-anthracite-800;
    }

    @each $color-name, $color-value in $collection-item-colors {
      &--#{$color-name} &__left-border {
        background-color: $color-value;
      }

      &--active.collection-item--#{$color-name} {
        background-color: $color-value;
        border-color: $color-value;
      }
    }
  }
</style>
