<template>
  <mi-dialog
    class="pm-change-dialog"
    no-backdrop-dismiss
    :model-value="modelValue"
    :close-icon="false"
  >
    <div class="pm-change-dialog__content">
      <div class="pm-change-dialog__content-header">
        <div>
          <h3 class="q-ma-none">
            Do you want to change product model?
          </h3>
        </div>
        <div>
          <mi-tabs v-model="activeTab">
            <mi-tab
              v-for="tab in tabs"
              :key="tab.key"
              :name="tab.key"
              :label="tab.label"
              :badge="badgeValue(tab.modificationType)"
              :disable="disableTab(tab.modificationType)"
              :product-model-change="true"
            ></mi-tab>
          </mi-tabs>
        </div>
      </div>
      <div class="pm-change-dialog__content-info">
        <div class="info divider">
          <div class="label">From</div>
          <div>{{ selectedProductModel.businessName }}</div>
        </div>
        <div class="info">
          <div class="label">To</div>
          <div>{{ modifiedElements.pmIdentifier }}</div>
        </div>
      </div>
      <div v-if="activeTab === tabs.ELEMENTS_TO_BE_REMOVED.key" class="elements-info">
        <span class="text-subtitle2 text-weight-regular">
          These elements are not available in the collection(s) for {{ modifiedElements.pmIdentifier }}.
        </span>
      </div>
      <div v-else class="elements-info">
        <span class="text-subtitle2 text-weight-regular">
          These elements are available to add in the collection(s) for {{ modifiedElements.pmIdentifier }}.
        </span>
      </div>
      <div class="pm-change-dialog__content-body">
        <mi-tab-panels v-model="activeTab" :animated="false">
          <mi-tab-panel :name="activeTab" class="q-px-none">
            <template v-if="activeTab === tabs.ELEMENTS_TO_BE_REMOVED.key">
              <remove-elements-tab
                :modified-elements="modifiedElements"
                :selected-world-type="selectedWorldType"
                :update-selected-world-type="updateSelectedWorldType"
              ></remove-elements-tab>
            </template>
            <template v-if="activeTab === tabs.ELEMENTS_TO_BE_ADDED.key">
              <add-elements-tab
                :handle-item-click="handleItemClick"
                :handle-sub-item-click="handleSubItemClick"
                :selected-added-elements-ids="selectedAddedElementsIds"
                :modified-elements="modifiedElements"
                :selected-world-type="selectedWorldType"
                :update-selected-world-type="updateSelectedWorldType"
              ></add-elements-tab>
            </template>
          </mi-tab-panel>
        </mi-tab-panels>
      </div>
      <div class="pm-change-dialog__content-footer">
        <div class="flex justify-end">
          <mi-btn class="q-mr-sm" flat @click="closeDialog"> Cancel </mi-btn>
          <mi-btn type="submit" :loading="submitLoading" @click="submit"> Confirm </mi-btn>
        </div>
      </div>
    </div>
  </mi-dialog>
</template>

<script>
  import { ref, onMounted } from 'vue'
  import { PM_CHANGE_TABS, SEARCH_RESULT_TYPES } from '@/constants'
  import RemoveElementsTab from '@/components/product-model/elements-change/RemoveElementsTab.vue'
  import AddElementsTab from '@/components/product-model/elements-change/AddElementsTab.vue'
  import { mapState, mapGetters } from 'vuex'
  import {
    filterElementByModificationType,
    countTotalSubElements
  } from '@/components/product-model/utils'

  export default {
    name: 'ProductModelChangeDialog',
    components: { AddElementsTab, RemoveElementsTab },
    props: {
      modelValue: {
        type: Boolean,
        required: true
      },
      modifiedElements: {
        type: Object,
        required: false,
        default: () => {}
      },
      closeDialog: {
        type: Function,
        required: false,
        default: () => {}
      }
    },
    emits: ['update:model-value', 'update:product-model'],
    setup(props) {
      const tabs = ref(PM_CHANGE_TABS)
      const selectedAddedElementsIds = ref({
        [SEARCH_RESULT_TYPES.OPTIONS]: new Map(),
        [SEARCH_RESULT_TYPES.COMPONENTS]: new Map()
      })
      const submitLoading = ref(false)

      const getTotalSubElements = (elements, modificationType, subElementType) => {
        const filteredElements = filterElementByModificationType(elements, modificationType)
        return countTotalSubElements(filteredElements, subElementType)
      }

      const getTotalElements = modificationType => {
        const { CHOICES, COMPONENT_VARIANTS } = SEARCH_RESULT_TYPES
        const { components, options } = props.modifiedElements

        const totalChoices = getTotalSubElements(options, modificationType, CHOICES)
        const totalKVs = getTotalSubElements(components, modificationType, COMPONENT_VARIANTS)

        return { total: totalChoices + totalKVs || 0, totalChoices, totalKVs }
      }

      const totalAddedElements = ref(getTotalElements(PM_CHANGE_TABS.ELEMENTS_TO_BE_ADDED.modificationType))
      const totalRemovedElements = ref(getTotalElements(PM_CHANGE_TABS.ELEMENTS_TO_BE_REMOVED.modificationType))

      const selectedWorldType = ref(SEARCH_RESULT_TYPES.OPTIONS)
      const activeTab = ref(PM_CHANGE_TABS.ELEMENTS_TO_BE_ADDED.key)

      const mountActivatedTab = () => {
        const { total: totalAdded } = totalAddedElements.value
        const { total: totalRemoved } = totalRemovedElements.value

        const isRemovedTab = totalAdded === 0 && totalRemoved > 0
        const world = isRemovedTab ? totalRemovedElements.value : totalAddedElements.value

        selectedWorldType.value = world.totalKVs > 0 && world.totalChoices === 0
          ? SEARCH_RESULT_TYPES.COMPONENTS : SEARCH_RESULT_TYPES.OPTIONS

        return isRemovedTab ? PM_CHANGE_TABS.ELEMENTS_TO_BE_REMOVED.key : PM_CHANGE_TABS.ELEMENTS_TO_BE_ADDED.key
      }

      const updateSelectedWorldType = worldTypeKey => {
        selectedWorldType.value = worldTypeKey
      }

      const setDefaultElementsByType = (elements, elementType, subElementType, field) => {
        elements.forEach(({ modificationType, element }) => {
          if (modificationType === 'ADDED') {
            element[subElementType].forEach(subElement => {
              const elementTypeValue = selectedAddedElementsIds.value[elementType]
              const subElementTypeValue = elementTypeValue.get(subElement[field]) || []
              elementTypeValue.set(subElement[field], [...subElementTypeValue, subElement.id])
            })
          }
        })
      }

      onMounted(() => {
        const { components, options } = props.modifiedElements

        activeTab.value = mountActivatedTab()

        setDefaultElementsByType(
          options, SEARCH_RESULT_TYPES.OPTIONS, SEARCH_RESULT_TYPES.CHOICES, 'optionId'
        )

        setDefaultElementsByType(
          components, SEARCH_RESULT_TYPES.COMPONENTS, SEARCH_RESULT_TYPES.COMPONENT_VARIANTS, 'componentId'
        )
      })

      return {
        activeTab,
        totalAddedElements,
        totalRemovedElements,
        tabs,
        selectedAddedElementsIds,
        mountActivatedTab,
        selectedWorldType,
        updateSelectedWorldType,
        submitLoading
      }
    },
    computed: {
      ...mapState(['user']),
      ...mapGetters('product-model', ['selectedProductModel'])
    },
    methods: {
      disableTab(modificationType) {
        if (modificationType === PM_CHANGE_TABS.ELEMENTS_TO_BE_ADDED.modificationType) {
          return this.totalAddedElements.total === 0
        }
        return this.totalRemovedElements.total === 0
      },
      getBadgeValue(value) {
        return value > 99 ? '+99' : value
      },
      badgeValue(modificationType) {
        if (modificationType === PM_CHANGE_TABS.ELEMENTS_TO_BE_ADDED.modificationType) {
          return this.getBadgeValue(this.totalAddedElements.total)
        }
        return this.getBadgeValue(this.totalRemovedElements.total)
      },
      handleItemClick(elementTypes, { item = {}, isChosen = false } = {}) {
        if (!isChosen) {
          const subItensIds = item[elementTypes.subItem]?.map(subItem => subItem.id) || []
          this.selectedAddedElementsIds[elementTypes.item].set(item.id, subItensIds)
        }
        else {
          this.selectedAddedElementsIds[elementTypes.item].delete(item.id)
        }
      },

      handleSubItemClick(elementTypes, { subItem = {}, isChosen = false } = {}) {
        const itemId = elementTypes.item === SEARCH_RESULT_TYPES.OPTIONS
          ? subItem.optionId
          : subItem.componentId

        const subItemsIds = this.selectedAddedElementsIds[elementTypes.item].get(itemId) || []

        if (!isChosen && !subItemsIds.includes(subItem.id)) {
          subItemsIds.push(subItem.id)
        }
        else {
          const index = subItemsIds.indexOf(subItem.id)
          if (index !== -1) {
            subItemsIds.splice(index, 1)
          }
        }

        if (!Object.keys(subItemsIds).length) {
          this.selectedAddedElementsIds[elementTypes.item].delete(itemId)
        }
        else {
          this.selectedAddedElementsIds[elementTypes.item].set(itemId, subItemsIds)
        }
      },

      filterComponents(uncheckedComponents) {
        const { completeComponents } = this.modifiedElements

        const filteredComponents = completeComponents.map(component => {
          let elements = { ...component }
          uncheckedComponents.forEach(element => {
            const kvIndex = elements.componentVariants.findIndex(kV => kV.id === element.id)
            const filteredKvs = elements.componentVariants.filter((_, index) => index !== kvIndex)
            elements = { ...elements, componentVariants: filteredKvs }
          })

          return elements
        })
        return filteredComponents
      },

      filterElements(uncheckedElements, completeElements, subElementType) {
        return completeElements.map(element => {
          let elements = { ...element }
          uncheckedElements.forEach(uncheckedEl => {
            const subItemIndex = elements[subElementType].findIndex(
              subItem => subItem.id === uncheckedEl.id
            )
            const filteredSubElements = elements[subElementType].filter(
              (_, index) => index !== subItemIndex
            )
            elements = { ...elements, [subElementType]: filteredSubElements }
          })
          return elements
        })
      },

      uncheckElementsByType(subItems, elementsType, field) {
        return subItems.filter(item => {
          const selectedIds = this.selectedAddedElementsIds[elementsType]
          const hasParentKey = selectedIds.has(item[field])
          if (!hasParentKey) return true
          const elementIds = selectedIds.get(item[field])
          return !elementIds.includes(item.id)
        })
      },
      getUncheckedElements() {
        const { components, options } = this.modifiedElements

        const uncheckedOptions = options.flatMap(({ element }) => this.uncheckElementsByType(
          element.choices, SEARCH_RESULT_TYPES.OPTIONS, 'optionId'
        ))
        const uncheckedComponents = components.flatMap(({ element }) => this.uncheckElementsByType(
          element.componentVariants, SEARCH_RESULT_TYPES.COMPONENTS, 'componentId'
        ))
        return { uncheckedOptions, uncheckedComponents }
      },
      updateElementsToBeChanged() {
        const { completeOptions, completeComponents } = this.modifiedElements
        const { uncheckedOptions, uncheckedComponents } = this.getUncheckedElements()

        const updatedOptions = this.filterElements(
          uncheckedOptions,
          completeOptions,
          SEARCH_RESULT_TYPES.CHOICES
        )

        const updatedComponents = this.filterElements(
          uncheckedComponents,
          completeComponents,
          SEARCH_RESULT_TYPES.COMPONENT_VARIANTS
        )

        return { updatedOptions, updatedComponents }
      },
      async submit() {
        this.submitLoading = true
        try {
          const { updatedOptions: options, updatedComponents: components } = this.updateElementsToBeChanged()
          this.$emit('update:product-model', { detectElementsData: { options, components } })
          this.closeDialog()
        }
        catch (err) {
          throw new Error('Something went wrong trying to update the elements changes', err)
        }
        finally {
          this.submitLoading = false
        }
      }
    }
  }
</script>

<style lang="scss">
  $table-border-width: 1px;

  .pm-change-dialog {
    .q-dialog__inner > .mi-card {
      width: 1079px;
      max-width: 1079px;
      min-width: 580px;
    }

    .mi-card > .mi-card__section {
      padding: 0;
    }

    &__content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .elements-info {
        width: 100%;
        padding: 16px 24px;
        border-top: $table-border-width solid $mi-silver-50;

        .text-weight-regular {
          line-height: 16px;
          font-size: 16px;
        }
      }

      .q-tab-panels {
        height: 100%;
      }

      .q-tab__content > .q-tab__label {
        font-size: 14px;
        font-family: $mi-typography-font-family-condensed;
        padding-right: 8px;
      }

      &-header {
        background-color: $mi-anthracite-50;
        padding: 24px 24px 0;
        width: 100%;
        display: flex;
        flex-direction: column;

        > :last-child {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: auto;
        }

        .mi-tab.disabled {
          color: $mi-silver-400;
        }
      }

      &-body {
        height: 585px;
        padding: 0 24px 12px;
        width: 100%;

        .q-tab-panel {
          padding-top: 0;
        }
      }

      &-footer {
        padding: 0 24px 24px;
        width: 100%;
      }

      &-info {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .info {
          padding: 16px 24px;
          width: 50%;
          display: flex;
        }

        .label {
          font-family: $mi-typography-font-family-condensed;
          font-weight: 700;
          padding-right: 5px;
        }

        .divider {
          border-right: solid;
          border-right-color: $mi-silver-50;
        }
      }
    }
  }
</style>
