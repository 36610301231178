<template>
  <div class="solution-elements-setup-selection">
    <div class="solution-elements-setup-selection__header">
      <p>Selecting components</p>
    </div>
    <div class="solution-elements-setup__content">
      <div class="solution-elements-setup-selection__filter">
        <!-- Search field -->
        <mi-text-field
          v-model="searchInputText"
          bg-color="white"
          debounce="300"
          placeholder="Filter components"
          hide-bottom-space
          outlined
          clearable
          :clear-value="searchArrayByInputText"
          @update:model-value="searchArrayByInputText"
        >
        </mi-text-field>
      </div>
      <div class="solution-elements-setup-selection__select-all">
        <mi-checkbox
          :disable="!filteredComponents.length"
          :model-value="selectAllComponents"
          label="Select all"
          @update:model-value="handleSelectAllClick"
        ></mi-checkbox>
        <label>{{ countSelectedObjects() }}/{{ filteredComponents.length }}</label>
      </div>
      <div v-if="filteredComponents.length" class="solution-elements-setup-selection__list">
        <mi-virtual-scroll
          ref="miVirtualScroll"
          class="solution-elements-setup-selection__scroll-container"
          :items="filteredComponents"
          virtual-scroll-item-size="46"
          virtual-scroll-slice-size="10"
          separator
        >
          <template #default="{ item }">
            <mi-list-item
              :key="item.id"
              class="items-center"
            >
              <mi-list-item-section
                side
              >
                <mi-checkbox
                  :model-value="item.selectedForLE"
                  @update:model-value="handleSelectClick(item)"
                ></mi-checkbox>
              </mi-list-item-section>

              <!-- Title -->
              <search-results-item-title
                :persistent="true"
                :code-id="item.id2"
                :name="item.name"
              ></search-results-item-title>
            </mi-list-item>
          </template>
        </mi-virtual-scroll>
      </div>
      <div v-else class="solution-elements-setup-selection__list-empty">
        <mi-separator></mi-separator>
        <p class="solution-elements-setup-selection__list-empty--header">No components</p>
        <p class="solution-elements-setup-selection__list-empty--content">
          Search for components to select which one will have the LE
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import SearchResultsItemTitle from '@/components/search/search-results/SearchResultsItemTitle.vue'
  import { SET_COMPONENT_FOR_LE, SET_COMPONENTS_FOR_LE } from '@/store/modules/search/mutationTypes'
  import { SEARCH_RESULT_TYPES } from '@/constants'
  import MiSeparator from '@/packages/@mi-library/MiSeparator/MiSeparator.vue'
  import MiVirtualScroll from '@/packages/@mi-library/MiVirtualScroll/MiVirtualScroll.vue'

  const { mapState, mapMutations } = createNamespacedHelpers('search')
  export default {
    name: 'ComponentListSelection',
    components: { MiVirtualScroll, MiSeparator, SearchResultsItemTitle },
    data: () => ({
      selectAllComponents: false,
      filteredComponents: [],
      searchInputText: ''
    }),
    computed: {
      ...mapState(['selectedElements'])
    },
    beforeMount() {
      this.allSelected()
      this.searchArrayByInputText()
    },
    methods: {
      ...mapMutations({ SET_COMPONENT_FOR_LE, SET_COMPONENTS_FOR_LE }),

      allSelected() {
        this.selectAllComponents = this.selectedElements[SEARCH_RESULT_TYPES.COMPONENTS]
          .every(elements => elements.selectedForLE === true)
          || (this.filteredComponents.length === this.countSelectedObjects())
      },
      countSelectedObjects() {
        return this.filteredComponents
          .reduce((count, component) => count + (component.selectedForLE === true ? 1 : 0), 0)
      },
      searchArrayByInputText(inputText) {
        this.searchInputText = inputText ? inputText.toLowerCase() : ''
        this.filteredComponents = this.selectedElements[SEARCH_RESULT_TYPES.COMPONENTS]
          .filter(element => {
            const id = element.id2 ? element.id2.toLowerCase() : ''
            const name = element.name ? element.name.toLowerCase() : ''
            return name.includes(this.searchInputText) || id.includes(this.searchInputText)
          })
        this.allSelected()
      },
      handleSelectAllClick() {
        this.selectAllComponents = !this.selectAllComponents

        if (this.filteredComponents.length < this.selectedElements.components.length) {
          this.filteredComponents.forEach(component => this.handleSelectClick(component))
        }
        else {
          this.SET_COMPONENTS_FOR_LE({ selectedForLE: this.selectAllComponents })
        }

        this.searchArrayByInputText(this.searchInputText)
        this.allSelected()
      },
      handleSelectClick(component) {
        if (!(component && component.id)) return
        this.SET_COMPONENT_FOR_LE({
          selectedElementId: component.id
        })
        this.allSelected()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .solution-elements-setup-selection {
    margin-top: 16px;

    &__header {
      font-family: $mi-typography-font-family-condensed;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
    }

    &__select-all {
      padding: 12px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;

      label {
        color: var(--color-man-anthracite-500, #5b6f85);
        font-family: $mi-typography-font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    &__list {
      .q-item {
        background: var(--accent-colors-green-green-050, $mi-green-50);
      }
    }

    &__list-empty {
      margin-top: 16px;

      &--header {
        margin-top: 16px;
        color: var(--color-man-anthracite-800, #303c49);
        text-align: center;
        font-family: $mi-typography-font-family-condensed;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
      }

      &--content {
        color: var(--color-man-anthracite-800, #303c49);
        text-align: center;
        font-family: $mi-typography-font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }

    &__scroll-container {
      max-height: 460px;
      overflow-y: auto;
    }
  }
</style>
