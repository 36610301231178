<template>
  <div>
    <mi-select
      v-show="shouldRender()"
      :model-value="selectedSearchProductModel"
      :menu-offset="[0, 3]"
      class="product-model-select bg-white"
      menu-anchor="bottom right"
      menu-self="top right"
      option-label="displayName"
      options-selected-class="text-accent"
      popup-content-class="product-model-select__dropdown"
      outlined
      hide-hint
      data-cy="product-model-select"
    >
      <mi-menu>
        <mi-list dense>
          <mi-list-item
            v-for="productModel in searchProductModels"
            :key="productModel.id"
            dense
            clickable
            :class="{ 'text-accent': isProductModelSelected(productModel) }"
          >
            <template v-if="!productModel.child">
              <mi-list-item-section
                v-close-popup
                @click="applySelectedProductModel(productModel)"
              >
                {{ productModel.businessName }}
              </mi-list-item-section>
            </template>

            <template v-else>
              <mi-list-item-section>
                {{ productModel.businessName }}
              </mi-list-item-section>

              <mi-list-item-section side>
                <mi-icon name="caret-right"></mi-icon>
              </mi-list-item-section>

              <mi-menu anchor="top end" self="top start">
                <mi-list dense>
                  <mi-list-item
                    v-for="userExport in productModel.child"
                    :key="userExport.id"
                    clickable
                    :class="{ 'text-accent': isProductModelSelected(userExport) }"
                  >
                    <mi-list-item-section
                      @click="applySelectedProductModel(userExport)"
                    >
                      {{ userExport?.onDemandExportMetadata?.exportName }}
                    </mi-list-item-section>
                  </mi-list-item>

                  <mi-list-item v-if="!productModel.child.length" class="text-grey">
                    <mi-list-item-section class="text-grey">No results</mi-list-item-section>
                  </mi-list-item>
                </mi-list>
              </mi-menu>
            </template>
          </mi-list-item>
        </mi-list>
      </mi-menu>
    </mi-select>
    <product-model-change-dialog
      v-if="isProductModelSelectedDialogShown"
      v-model="isProductModelSelectedDialogShown"
      :modified-elements="modifiedElements"
      :close-dialog="closeDialog"
      @update:product-model="updateProductModelWithDetectedChanges"
    >
    </product-model-change-dialog>
  </div>
</template>

<script>
  import {
    CLEAR_SEARCH_RESULTS,
    REPLACE_SELECTED_ELEMENTS,
    SET_ELEMENTS_LOADING_STATE,
    UPDATE_PREVIEW_ELEMENTS_AFTER_PM_CHANGED
  } from '@/store/modules/search/mutationTypes'
  import { SELECT_PRODUCT_MODEL, SELECT_SEARCH_PRODUCT_MODEL, UPDATE_SEARCH_PRODUCT_MODELS_LIST }
    from '@/store/modules/product-model/mutationTypes'

  import { PRODUCT_MODELS_ROUTE } from '@/router/routeNames'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
  import { detectElementsChangeFromProductModel, postSummary } from '@/api'
  import ProductModelChangeDialog from '@/components/product-model/ProductModelChangeDialog.vue'
  import { PROD_MODEL_CHANGED, VM_ON_DEMAND_EXPORT_SELECTED } from '@/utils/analytics/constants'
  import { PM_TYPE } from '@/constants'
  import { base64EncodeUnicode } from '@/utils/base64'
  import notify from '@/utils/notify'

  export default {
    name: 'ProductModelSelect',
    components: {
      ProductModelChangeDialog
    },
    data: () => ({
      isProductModelSelectedDialogShown: false,
      modifiedElements: {},
      encodedBusinessName: '',
      productModelsList: [],
      productModelToBeChanged: {}
    }),
    computed: {
      ...mapState(['user']),
      ...mapState('product-model', [
        'productModels',
        'areProductModelsLoading',
        'searchProductModels',
        'selectedSearchProductModel'
      ]),
      ...mapState('rule-validation', ['userExports']),
      ...mapGetters('product-model', ['selectedProductModel', 'hasSelectedSearchProductModel']),
      ...mapGetters('search', ['selectedOptions', 'selectedComponents']),

      isNotProductModelsRoute() {
        return !!this.$route.name && !this.$route.matched?.some(route => route.name === PRODUCT_MODELS_ROUTE.name)
      },
      hasElementsToDetectChanges() {
        return (this.selectedOptions && !!this.selectedOptions.length)
          || (this.selectedComponents && !!this.selectedComponents.length)
      }
    },
    async created() {
      const [
        productModels,
        userExports
      ] = await Promise.allSettled([
        this.getProductModelsList(),
        this.fetchUserExportsByTypes()
      ])
      this.UPDATE_SEARCH_PRODUCT_MODELS_LIST({ productModels, userExports })
      this.setProductModelOnCreated()
    },
    methods: {
      ...mapMutations('product-model', {
        SELECT_PRODUCT_MODEL,
        SELECT_SEARCH_PRODUCT_MODEL,
        UPDATE_SEARCH_PRODUCT_MODELS_LIST
      }),
      ...mapActions('product-model', ['getProductModelsList']),
      ...mapActions('rule-validation', ['fetchUserExportsByTypes']),
      ...mapActions('search', [
        'getChoices',
        'getComponentVariants',
        'getComponents',
        'getOptions',
        'updateListIfElementIdChange'
      ]),
      ...mapMutations('search', {
        CLEAR_SEARCH_RESULTS,
        SET_ELEMENTS_LOADING_STATE,
        UPDATE_PREVIEW_ELEMENTS_AFTER_PM_CHANGED,
        REPLACE_SELECTED_ELEMENTS
      }),

      isProductModelSelected(productModel) {
        return productModel.id === this.selectedSearchProductModel.id
      },
      getPmCached() {
        const mainPm = this.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_PRODUCT_MODEL)
        const vmPm = this.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_VM_PRODUCT_MODEL)

        const isVmPmEmpty = Object.keys(vmPm || {}).length === 0
        return isVmPmEmpty ? mainPm : vmPm
      },
      findOnDemandExportById(onDemandExportId) {
        return this.searchProductModels.flatMap(
          searchProductModel => searchProductModel.child || [searchProductModel]
        ).find(searchProductModelChild => searchProductModelChild.id === onDemandExportId)
      },
      setProductModelOnCreated() {
        const pmCached = this.getPmCached()
        if (pmCached.productModelType === PM_TYPE.PRODUCT_MODEL || pmCached.encodedBusinessName) {
          this.SELECT_PRODUCT_MODEL(pmCached.encodedBusinessName)
          this.SELECT_SEARCH_PRODUCT_MODEL(this.selectedProductModel)
          const { id, encodedBusinessName, productModelType } = this.selectedProductModel
          this.$q.localStorage.set(process.env.VUE_APP_STORAGE_KEY_VM_PRODUCT_MODEL, {
            id, encodedBusinessName, productModelType
          })
        }
        else if (pmCached.productModelType === PM_TYPE.USER_EXPORTS) {
          const onDemandExport = this.findOnDemandExportById(pmCached.id)

          if (!onDemandExport) {
            this.$router.push({ name: PRODUCT_MODELS_ROUTE.name })
            return
          }

          const { id, encodedBusinessName, productModelType } = onDemandExport
          this.$q.localStorage.set(process.env.VUE_APP_STORAGE_KEY_VM_PRODUCT_MODEL, {
            id, encodedBusinessName, productModelType
          })

          this.SELECT_SEARCH_PRODUCT_MODEL(onDemandExport)
        }
      },
      async updateSearchElementsWithNewProductModel({ activateLoading = true } = {}) {
        try {
          const { searchString = '' } = this.$route.query || {}

          if (!searchString) return

          const productModel = this.productModelToBeChanged
          const isUserExport = productModel.productModelType === PM_TYPE.USER_EXPORTS

          const query = searchString?.split(';')
            .map(item => base64EncodeUnicode(item.trim()))
            .filter(item => item.length).join(',') || ''

          const params = {
            query,
            pmIdentifier: isUserExport ? productModel.id : productModel.encodedBusinessName,
            pmType: productModel.productModelType || PM_TYPE.PRODUCT_MODEL
          }
          activateLoading && this.SET_ELEMENTS_LOADING_STATE(true)
          await Promise.all([this.getStructureElements(params)])
          this.UPDATE_PREVIEW_ELEMENTS_AFTER_PM_CHANGED()
        }
        catch (err) {
          throw new Error(
            `Error refreshing the preview elements ${ this.productModelToBeChanged.id }`
          )
        }
        finally {
          this.SET_ELEMENTS_LOADING_STATE(false)
        }
      },
      shouldRender() {
        const hasSelectedPmSearch = this.hasSelectedSearchProductModel
        return this.isNotProductModelsRoute && hasSelectedPmSearch
      },
      async getStructureElements(params) {
        await Promise.all([
          this.getChoices(params),
          this.getComponentVariants(params),
          this.getComponents(params),
          this.getOptions(params)
        ])
      },
      async applySelectedProductModel(productModel) {
        if (!productModel) return

        try {
          /* The condition has become more complex than anticipated due to the consideration
            of Workaround PMs as UserExports, despite having an encodedBussinessName.
            Additionally, ETAA user exports normally have an encodedBussinessName beacause users
            assigne the name "Workaround" to the export.
          */
          const pmName = productModel.productModelType === PM_TYPE.PRODUCT_MODEL
            || (productModel.productModelType === PM_TYPE.USER_EXPORTS && !productModel.onDemandExportMetadata)
            ? productModel.businessName
            : productModel.onDemandExportMetadata?.exportName
          this.$q.loading.show({
            message: `Loading "${ pmName }" product model. Please wait ...`
          })
          await this.detectElementsChangeFromPM(productModel)
          // Analytics
          recordAnalytics(PROD_MODEL_CHANGED)
        }
        finally {
          this.$q.loading.hide()
        }
      },
      changeProductModel(productModel) {
        const pmContent = {
          id: productModel.id,
          encodedBusinessName: productModel.encodedBusinessName,
          productModelType: productModel.productModelType
        }

        if (pmContent.encodedBusinessName) {
          this.$q.localStorage.set(process.env.VUE_APP_STORAGE_KEY_PRODUCT_MODEL, pmContent)
          this.SELECT_PRODUCT_MODEL(pmContent.encodedBusinessName)
        }

        this.SELECT_SEARCH_PRODUCT_MODEL(productModel)
        this.$q.localStorage.set(process.env.VUE_APP_STORAGE_KEY_VM_PRODUCT_MODEL, pmContent)

        if (pmContent.productModelType === PM_TYPE.USER_EXPORTS) {
          recordAnalytics(VM_ON_DEMAND_EXPORT_SELECTED)
        }
      },
      updateProductModelWithDetectedChanges({ detectElementsData, saveSummary = true }) {
        const { encodedBusinessName, productModelType } = this.productModelToBeChanged

        this.updateListIfElementIdChange({ detectElementsData })

        saveSummary && encodedBusinessName && postSummary({
          name: `${ this.user.username }_CurrentSelection`,
          pmEncodedBusinessName: encodedBusinessName,
          pmType: productModelType,
          options: this.selectedOptions,
          components: this.selectedComponents,
          currentSelection: true
        })

        this.changeProductModel(this.productModelToBeChanged)
        this.updateSearchElementsWithNewProductModel({ activateLoading: false })
      },
      async detectElementsChange({ id, encodedBusinessName, productModelType }) {
        const params = {
          options: this.selectedOptions,
          components: this.selectedComponents,
          pmInfo: {
            pmType: productModelType,
            pmIdentifier: productModelType === PM_TYPE.PRODUCT_MODEL ? encodedBusinessName : id
          },
          oldPmInfo: {
            pmType: this.selectedSearchProductModel.productModelType,
            pmIdentifier: this.selectedSearchProductModel.productModelType === PM_TYPE.PRODUCT_MODEL
              ? this.selectedSearchProductModel.encodedBusinessName
              : this.selectedSearchProductModel.id
          }
        }

        try {
          return await detectElementsChangeFromProductModel(params)
        }
        catch (e) {
          notify({
            title: `HTTP Error ${ e.status || '' }`,
            content: 'Error when changing the Product Model',
            type: 'negative'
          })
          throw e
        }
      },
      closeDialog() {
        this.isProductModelSelectedDialogShown = false
      },
      async detectElementsChangeFromPM(productModel) {
        this.productModelToBeChanged = productModel
        const { id, encodedBusinessName, productModelType } = productModel

        const data = await this.detectElementsChange({ id, encodedBusinessName, productModelType })

        if (this.hasElementsToDetectChanges) {
          if (!!data.modifiedOptions.length || !!data.modifiedComponents.length) {
            this.isProductModelSelectedDialogShown = true
            this.modifiedElements = {
              options: data.modifiedOptions,
              components: data.modifiedComponents,
              completeOptions: data.options,
              completeComponents: data.components,
              pmIdentifier: productModel?.businessName
            }

            if (productModelType === PM_TYPE.USER_EXPORTS) {
              this.modifiedElements = {
                ...this.modifiedElements,
                pmIdentifier: !productModel.onDemandExportMetadata
                  ? productModel.displayName : productModel.onDemandExportMetadata.exportName
              }
            }
          }
          else {
            this.updateProductModelWithDetectedChanges({ detectElementsData: data })
          }
        }
        else {
          this.changeProductModel(this.productModelToBeChanged)
          this.updateSearchElementsWithNewProductModel({ activateLoading: false })
          this.CLEAR_SEARCH_RESULTS()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .product-model-select__dropdown {
    // stylelint-disable declaration-no-important
    min-width: 220px !important;
  }

  .q-item__section--side > .q-icon {
    font-size: 10px !important;
  }

  .mi-list-item.q-item--dense {
    min-height: 50px !important;
  }

  .q-list--dense > .q-item {
    min-height: 50px !important;
  }
</style>
